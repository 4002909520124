import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthDialogContainerComponent } from '@app/auth/auth-dialog-container/auth-dialog-container.component';
import { AuthService, SendOtpResponse } from '@bc-core-lib';
import { FormErrorsService } from '@shared/form-controls/form-errors/form-errors.service';
import { FormFieldComponent } from '@shared/form-controls/form-field/form-field.component';
import { InputDirective } from '@shared/form-controls/input/input.directive';
import { SubmitButtonComponent } from '@shared/form-controls/submit-button/submit-button.component';
import { finalize } from 'rxjs/operators';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { OtpDialogComponent } from '../otp-dialog/otp-dialog.component';

@Component({
  selector: 'us2-forgot-password-dialog',
  standalone: true,
  imports: [
    AuthDialogContainerComponent,
    FormFieldComponent,
    InputDirective,
    ReactiveFormsModule,
    SubmitButtonComponent,
  ],
  templateUrl: './forgot-password-dialog.component.html',
  styleUrl: './forgot-password-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordDialogComponent {
  form: FormGroup;
  title = $localize`Forgot your password?`;
  subtitle = $localize`Enter your registered email below to receive password reset instructions.`;
  fieldLabels = {
    username: $localize`Email`,
  };
  isSubmitting = signal(false);

  #fb = inject(FormBuilder);
  #authService = inject(AuthService);
  #formErrorsService = inject(FormErrorsService);
  #dialogRef = inject(MatDialogRef<ForgotPasswordDialogComponent>);
  #dialog = inject(MatDialog);

  constructor() {
    this.createForm();
  }

  onSubmit() {
    if (this.form.invalid || this.form.pristine) {
      return;
    }

    this.isSubmitting.set(true);

    this.#authService.forgotPassword(this.form.value)
      .pipe(
        finalize(() => this.isSubmitting.set(false)),
      )
      .subscribe(
        resp => this.toOtp(resp),
        (resp: HttpErrorResponse) => this.#formErrorsService.setFormErrors(resp, this.form),
      );
  }

  login() {
    this.#dialogRef.close();
    this.#dialog.open(LoginDialogComponent);
  }

  private createForm() {
    this.form = this.#fb.group({
      username: [ '', [ Validators.required, Validators.email ] ],
    });
  }

  private toOtp(resp: SendOtpResponse) {
    const data = {
      username: this.form.value.username,
      expiry: resp.expiry,
      prefix: resp.prefix,
    }
    this.#dialogRef.close();
    this.#dialog.open(OtpDialogComponent, { data });
  }
}
