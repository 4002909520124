<form [formGroup]="form" ngClass.lt-md="mobile">
  <div formArrayName="inputs" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
    <ng-container *ngIf="prefix">
      <div *ngFor="let char of prefixArray" class="prefix-char otp-item">
        {{ char }}
      </div>

      <div class="prefix-divider">-</div>
    </ng-container>

    <div class="input-container" [class]="{ focused: i === focusedIndex, disabled: disabled }"
         *ngFor="let input of inputs.controls; index as i">

      <input class="otp-input otp-item" [class]="backgroundClass" #input [maxlength]="1" [formControlName]="i"
             (paste)="onPaste($event)" [class.empty]="!input.value" (beforeinput)="onBeforeInput($event, i)"
             (input)="onInput($event, i)" (focus)="focusedIndex = i" (blur)="focusedIndex = null">
    </div>
  </div>
</form>
