<us2-dialog-container>
  <div class="dialog-header flex flex-col items-center">
    @if (logo()) {
      <div class="image-container">
        <img class="logo" [ngSrc]="logo()" alt="Store Logo" fill>
      </div>
    }

    <div class="title">
      {{ title }}
    </div>

    @if (subtitle) {
      <div class="subtitle text-center">
        {{ subtitle }}
      </div>
    }
  </div>

  <ng-content></ng-content>

  <div class="auth-actions w-full" us2-dialog-actions>
    <ng-content select="[auth-actions]"></ng-content>
  </div>
</us2-dialog-container>

