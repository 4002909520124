import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { OtpInputComponent } from './otp-input.component';


@NgModule({
  declarations: [
    OtpInputComponent,
  ],
  exports: [
    OtpInputComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ]
})
export class OtpInputModule {
}
