@if (loginProviders().length) {
  <div class="flex flex-row items-center gap-x-2.5 my-6 text-xs">
    <hr class="grow">
    <span i18n>or</span>
    <hr class="grow">
  </div>
  
  <div class="social-row flex flex-row justify-between items-center gap-x-5">
    @for (provider of loginProviders(); track provider) {
      <button (click)="onLogin(provider)" us2-button stroked>
  
        <div class="provider mat-flat-button flex flex-row justify-center gap-3.5 items-center">
          <mat-icon class="provider-icon" [svgIcon]="'ic:login-' + providerName(provider?.name)"></mat-icon>
          <span i18n class="provider-name">Continue with {{ provider?.name }}</span>
        </div>
  
      </button>
    }
  </div>
}