import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CustomerLoginService, LoginProvider } from '@bc-core-lib';
import { ButtonComponent } from '@shared/ui-components/button/button.component';


@Component({
  selector: 'us2-social-login',
  standalone: true,
  imports: [
    ButtonComponent,
    MatIconModule,
  ],
  templateUrl: './social-login.component.html',
  styleUrl: './social-login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialLoginComponent {
  loginProviders: Signal<LoginProvider[]>;
  redirectUrl: Map<string, string> = new Map<string, string>(new Map());

  #customerLoginService = inject(CustomerLoginService);

  constructor() {
    this.loginProviders = this.#customerLoginService.filteredOauthProviders;
  }

  ngOnInit(): void {
    this.#customerLoginService.initList();
  }

  onLogin(provider: LoginProvider) {
    window.open(`${provider.url}?redirectUrl=${window.location.origin}`, '_self');
  }

  providerName(providerName: string): string {
    return providerName.toLowerCase();
  }
}
