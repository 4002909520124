<form class="flex flex-col gap-y-2.5" [formGroup]="form">
  <us2-auth-dialog-container [title]="title">

    <us2-form-field label="New password" i18n-label>
      <input type="password" formControlName="newPassword" us2Input />

      <mat-error>
        <bcc-form-field-error></bcc-form-field-error>
      </mat-error>
    </us2-form-field>

    <us2-form-field label="Confirm password" i18n-label>
      <input type="password" formControlName="confirmPassword" us2Input />

      <mat-error>
        <bcc-form-field-error></bcc-form-field-error>
      </mat-error>
    </us2-form-field>

    <us2-form-errors></us2-form-errors>


    <ng-container us2-dialog-actions>
      <div class="flex flex-row gap-x-3">
        <button us2-button stroked mat-dialog-close class="flex-1">
          Cancel
        </button>
  
        <us2-submit-button color="primary" i18n (click)="onSubmit()" [isLoading]="isLoading" class="flex-1">
          Submit
        </us2-submit-button>
      </div>


    </ng-container>

  </us2-auth-dialog-container>
</form>