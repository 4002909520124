<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <us2-auth-dialog-container [title]="title">

    <div class="flex flex-col gap-y-3.5">
      <us2-form-field [label]="fieldLabels.firstName">
        <input us2Input formControlName="firstName">
      </us2-form-field>

      <us2-form-field [label]="fieldLabels.lastName">
        <input us2Input formControlName="lastName">
      </us2-form-field>

      <us2-form-field [label]="fieldLabels.email">
        <input us2Input formControlName="email" type="email">
      </us2-form-field>

      <us2-form-field [label]="fieldLabels.mobileNumber">
        <input us2Input formControlName="mobileNumber">
      </us2-form-field>

      <us2-form-field [label]="fieldLabels.password">
        <input us2Input formControlName="password" type="password">
      </us2-form-field>

      <us2-form-field [label]="fieldLabels.confirmPassword">
        <input us2Input formControlName="confirmPassword" type="password">
      </us2-form-field>

<!--      <div>-->
<!--        <us2-checkbox formControlName="allowEmail" i18n>-->
<!--          Email me news and offers-->
<!--        </us2-checkbox>-->
<!--      </div>-->
    </div>


    <ng-container auth-actions>
      <div class="cta flex flex-row gap-x-5">
        <button us2-button i18n class="flex-1" stroked (click)="login()" type="button">
          Log in
        </button>

        <us2-submit-button class="flex-1" color="primary" [isLoading]="isSubmitting()">
          Create an account
        </us2-submit-button>
      </div>

      <us2-social-login></us2-social-login>
    </ng-container>

  </us2-auth-dialog-container>
</form>