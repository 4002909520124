<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <us2-auth-dialog-container [title]="title" [subtitle]="subtitle">

    <div class="flex flex-col gap-y-3.5">
      <us2-form-field [label]="fieldLabels.username">
        <input us2Input formControlName="username">
      </us2-form-field>
    </div>

    <ng-container auth-actions>
      <div class="cta flex flex-row gap-x-5">
        <us2-submit-button class="flex-1" color="primary" [isLoading]="isSubmitting()">
          Submit
        </us2-submit-button>
      </div>

      <div class="flex flex-row justify-center gap-x-2.5 my-6 text-sm">
        Remembered your password?<b (click)="login()" class="cursor-pointer">Sign in here</b>
      </div>
    </ng-container>
  </us2-auth-dialog-container>
</form>