import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthDialogContainerComponent } from '@app/auth/auth-dialog-container/auth-dialog-container.component';
import { SignUpDialogComponent } from '@app/auth/sign-up-dialog/sign-up-dialog.component';
import { AuthService } from '@bc-core-lib';
import { CheckboxComponent } from '@shared/form-controls/checkbox/checkbox.component';
import { FormErrorsService } from '@shared/form-controls/form-errors/form-errors.service';
import { FormFieldComponent } from '@shared/form-controls/form-field/form-field.component';
import { InputDirective } from '@shared/form-controls/input/input.directive';
import { SubmitButtonComponent } from '@shared/form-controls/submit-button/submit-button.component';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { finalize } from 'rxjs/operators';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { SocialLoginComponent } from '../social-login/social-login.component';

@Component({
  selector: 'us2-login-dialog',
  standalone: true,
  imports: [
    AuthDialogContainerComponent,
    FormFieldComponent,
    InputDirective,
    ReactiveFormsModule,
    SubmitButtonComponent,
    ButtonComponent,
    CheckboxComponent,
    SocialLoginComponent,
  ],
  templateUrl: './login-dialog.component.html',
  styleUrl: './login-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginDialogComponent {
  form: FormGroup;
  title = $localize`Log in with your email or mobile number to checkout`;
  fieldLabels = {
    username: $localize`Email address`,
    password: $localize`Password`,
  };
  isSubmitting = signal(false);

  #fb = inject(FormBuilder);
  #authService = inject(AuthService);
  #formErrorsService = inject(FormErrorsService);
  #dialogRef = inject(MatDialogRef<LoginDialogComponent>);
  #dialog = inject(MatDialog);
  formError = signal<string>(null);

  constructor() {
    this.createForm();
  }

  onSubmit() {
    if (this.form.invalid || this.form.pristine) {
      return;
    }

    this.isSubmitting.set(true);

    this.#authService.login(this.form.value)
      .pipe(
        finalize(() => this.isSubmitting.set(false)),
      )
      .subscribe(
        () => this.#dialogRef.close(true),
        (resp: HttpErrorResponse) => this.formError.set(this.#formErrorsService.setFormErrors(resp, this.form)),
      );
  }

  forgotPassword(): void {
    this.#dialogRef.close();
    this.#dialog.open(ForgotPasswordDialogComponent);
  }

  signUp(): void {
    this.#dialogRef.close();
    this.#dialog.open(SignUpDialogComponent);
  }

  private createForm() {
    this.form = this.#fb.group({
      username: [ '', Validators.required ],
      password: [ '', Validators.required ],
      rememberMe: false,
    });
  }
}
