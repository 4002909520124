<us2-auth-dialog-container [title]="title" [subtitle]="subtitle">

  <div class="otp-input-container flex flex-row justify-center">
    @if (isLoading) {
      Loading...
    } @else {
      <bcc-otp-input [prefix]="prefix" [disabled]="isValidating || timedOut" (filled)="onOtpFilled($event)"
                      (inputChanged)="onOtpChanged()"></bcc-otp-input>
    }
  </div>

  @if (otpError) {
    <div class="otp-error text-center text-sm text-red-500" i18n>
      OTP is incorrect
    </div>
  }

  @if (!timedOut) {
    <div class="text-sm text-center">
      <span i18n>
        Didn't receive your OTP details?
      </span>

      @if (resendDisabled) {
        <span i18n>
          Resend in {{ resendIn }}
        </span>
      } @else {
        <span class="resend-otp underline cursor-pointer" [class.disabled]="resendDisabled" (click)="resendOtp()" i18n>
          Resend OTP
        </span>
      }

    </div>
  }

</us2-auth-dialog-container>