<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <us2-auth-dialog-container [title]="title">

    <div class="flex flex-col gap-y-3.5">
      <us2-form-field [label]="fieldLabels.username">
        <input us2Input formControlName="username">
      </us2-form-field>

      <us2-form-field [label]="fieldLabels.password">
        <input us2Input formControlName="password" type="password">
      </us2-form-field>

      <div class="flex flex-row justify-between items-center">
<!--        <us2-checkbox formControlName="rememberMe" i18n>-->
<!--          Remember me-->
<!--        </us2-checkbox>-->

        <a class="link cursor-pointer" (click)="forgotPassword()">Forgot your password</a>
      </div>
    </div>

    <div class="error-container">
      {{ formError() }}
    </div>

    <ng-container auth-actions>
      <div class="cta flex flex-row gap-x-5">
        <button us2-button i18n class="flex-1" stroked (click)="signUp()" type="button">
          Sign up
        </button>

        <us2-submit-button class="flex-1" color="primary" [isLoading]="isSubmitting()">
          Log in
        </us2-submit-button>
      </div>

      <us2-social-login></us2-social-login>
    </ng-container>
  </us2-auth-dialog-container>
</form>