import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthDialogContainerComponent } from '@app/auth/auth-dialog-container/auth-dialog-container.component';
import { AuthService } from '@bc-core-lib';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { OtpInputModule } from '@bc-libs/common-components/widgets/otp-input';
import { formatDistanceToNowStrict, isFuture } from 'date-fns';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';


@Component({
  selector: 'us2-otp-dialog',
  standalone: true,
  imports: [
    AuthDialogContainerComponent,
    OtpInputModule,
  ],
  templateUrl: './otp-dialog.component.html',
  styleUrl: './otp-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtpDialogComponent {
  title = $localize`Please enter the OTP to reset your password`;
  subtitle = $localize`A verification code has been sent to your registered email address. `;
  prefix: string;
  username: string;
  expiry: string;
  isLoading: boolean;
  isValidating: boolean;
  otpError: boolean;
  sendDate = new Date();
  timedOut: boolean;
  resendIn: string;
  resendDate: Date;
  resendTimeout = 30000;
  resendDisabled = true;

  #cdr = inject(ChangeDetectorRef);
  #authService = inject(AuthService);
  #dialogRef = inject(MatDialogRef<OtpDialogComponent>);
  #dialog = inject(MatDialog);

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { username: string, expiry: string, prefix: string },
  ) {
    this.username = this.data.username;
    this.expiry = this.data.expiry;
    this.prefix = this.data.prefix;

    this.runTimer();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onOtpChanged(): void {
    this.otpError = false;
  }

  onOtpFilled(otp: string): void {
    this.isValidating = true;

    this.#authService.validateOtp({ otp: `${this.prefix}-${otp}`, username: this.username })
      .pipe(
        finalize(() => {
          this.isValidating = false;
          this.#cdr.markForCheck();
        }),
      )
      .subscribe(
        resp => {
          const token = resp.token;
          this.toResetPassword(token);
        },
        () => this.otpError = true,
      );
  }

  resendOtp(): void {
    if (this.resendDisabled) {
      return;
    }

    this.otpError = false;
    this.isLoading = true;
    this.sendDate = new Date();

    this.#authService.forgotPassword({ username: this.username })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.#cdr.markForCheck();
        }),
      )
      .subscribe(resp => {
        this.prefix = resp.prefix;
        this.expiry = resp.expiry;
      });
  }

  private runTimer(): void {
    timer(0, 1000)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.resendDate = new Date(+this.sendDate + this.resendTimeout);
        this.resendIn = formatDistanceToNowStrict(this.resendDate);
        this.resendDisabled = isFuture(this.resendDate);

        this.timedOut = +new Date(this.expiry) - +new Date() <= 0;

        this.#cdr.markForCheck();
      });
  }

  private toResetPassword(token: string) {
    this.#dialogRef.close();
    this.#dialog.open(ResetPasswordDialogComponent, { data: { token, username: this.username } });
  }
}
