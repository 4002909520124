import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Input, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreService } from '@bc-core-lib';
import { FormFieldComponent } from '@shared/form-controls/form-field/form-field.component';
import { InputDirective } from '@shared/form-controls/input/input.directive';
import { DialogContainerComponent } from '@shared/ui-components/dialog-container/dialog-container.component';

@Component({
  selector: 'us2-auth-dialog-container',
  standalone: true,
  imports: [
    NgOptimizedImage,
    DialogContainerComponent,
    FormFieldComponent,
    InputDirective,
    ReactiveFormsModule
  ],
  templateUrl: './auth-dialog-container.component.html',
  styleUrl: './auth-dialog-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthDialogContainerComponent {
  @Input() title: string;
  @Input() subtitle: string;
  logo: Signal<string>;

  #storeService = inject(StoreService);

  constructor() {
    this.logo = computed(() => this.#storeService.current()?.logo?.thumbnailUrl);
  }
}
