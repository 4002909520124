import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthDialogContainerComponent } from '@app/auth/auth-dialog-container/auth-dialog-container.component';
import { LoginDialogComponent } from '@app/auth/login-dialog/login-dialog.component';
import { AuthService } from '@bc-core-lib';
import { BoxValidators } from '@bc-libs/core';
import { CheckboxComponent } from '@shared/form-controls/checkbox/checkbox.component';
import { FormErrorsService } from '@shared/form-controls/form-errors/form-errors.service';
import { FormFieldComponent } from '@shared/form-controls/form-field/form-field.component';
import { InputDirective } from '@shared/form-controls/input/input.directive';
import { SubmitButtonComponent } from '@shared/form-controls/submit-button/submit-button.component';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { finalize } from 'rxjs/operators';
import { SocialLoginComponent } from '../social-login/social-login.component';

@Component({
  selector: 'us2-sign-up-dialog',
  standalone: true,
  imports: [
    AuthDialogContainerComponent,
    ButtonComponent,
    CheckboxComponent,
    FormFieldComponent,
    InputDirective,
    ReactiveFormsModule,
    SubmitButtonComponent,
    SocialLoginComponent,
  ],
  templateUrl: './sign-up-dialog.component.html',
  styleUrl: './sign-up-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignUpDialogComponent {
  form: FormGroup;
  title = $localize`Start an account today`;
  fieldLabels = {
    firstName: $localize`First name`,
    lastName: $localize`Last name`,
    email: $localize`Email`,
    mobileNumber: $localize`Mobile no.`,
    password: $localize`Password`,
    confirmPassword: $localize`Confirm password`
  };
  isSubmitting = signal(false);

  #fb = inject(FormBuilder);
  #dialog = inject(MatDialog);
  #authService = inject(AuthService);
  #formErrorsService = inject(FormErrorsService);
  #dialogRef = inject(MatDialogRef<SignUpDialogComponent>);

  constructor() {
    this.createForm();
  }

  onSubmit(): void {
    if (this.form.invalid || this.form.pristine) {
      return;
    }

    this.isSubmitting.set(true);

    this.#authService.signUp(this.form.value)
      .pipe(
        finalize(() => this.isSubmitting.set(false)),
      )
      .subscribe(
        () => this.#dialogRef.close(),
        (resp: HttpErrorResponse) => this.#formErrorsService.setFormErrors(resp, this.form),
      );
  }

  login(): void {
    this.#dialogRef.close();
    this.#dialog.open(LoginDialogComponent);
  }

  private createForm() {
    this.form = this.#fb.group({
      firstName: [ '', Validators.required ],
      lastName: [ '', Validators.required ],
      email: [ '', [ Validators.required, Validators.email ] ],
      mobileNumber: [ '', Validators.required ],
      password: [ '', [ Validators.required, BoxValidators.passwordComplexity ] ],
      confirmPassword: [ '', Validators.required ],
      allowEmail: false,
    }, { validators: BoxValidators.matchPassword('password', 'confirmPassword') });
  }
}

