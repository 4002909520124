import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormFieldErrorModule } from '@bc-common/widgets/form-field-error';
import { AuthService } from '@bc-core-lib';
import { BoxValidators } from '@bc-core/utils';
import { AuthDialogContainerComponent } from '@app/auth/auth-dialog-container/auth-dialog-container.component';
import { finalize } from 'rxjs/operators';
import { FormErrorsService } from '@shared/form-controls/form-errors/form-errors.service';
import { FormFieldComponent } from '@shared/form-controls/form-field/form-field.component';
import { InputDirective } from '@shared/form-controls/input/input.directive';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { FormErrorsComponent } from '@shared/form-controls/form-errors/form-errors.component';
import { SubmitButtonComponent } from '@shared/form-controls/submit-button/submit-button.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';

@Component({
  selector: 'us2-reset-password-dialog',
  standalone: true,
  imports: [
    AuthDialogContainerComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormFieldComponent,
    InputDirective,
    ButtonComponent,
    FormFieldErrorModule,
    FormErrorsComponent,
    SubmitButtonComponent,
  ],
  templateUrl: './reset-password-dialog.component.html',
  styleUrl: './reset-password-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordDialogComponent {
  title = $localize`Set a new password`;
  form: FormGroup;
  isLoading: boolean;
  token: string;
  username: string;
  showPasswords = {
    new: false,
    confirm: false,
  };

  #fb = inject(FormBuilder);
  #cdr = inject(ChangeDetectorRef);
  #authService = inject(AuthService);
  #formErrorsService = inject(FormErrorsService);
  #dialogRef = inject(MatDialogRef<ResetPasswordDialogComponent>);
  #dialog = inject(MatDialog);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { token: string, username: string },
  ) {
    this.username = this.data.username;
    this.token = this.data.token;
    
    if (!this.token) {
      this.toLogin()
    }

    this.createForm();
  }

  toggleVisibility(name: string): void {
    this.showPasswords = { ...this.showPasswords, [ name ]: !this.showPasswords[ name ] };
  }

  toLogin() {
    this.#dialogRef.close();
    this.#dialog.open(LoginDialogComponent);
  }

  onSubmit() {
    if (this.form.invalid || this.form.pristine) {
      return;
    }

    const data = { ...this.form.value, username: this.username, token: this.token };

    this.isLoading = true;

    this.#authService.resetPassword(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.#cdr.markForCheck();
        }),
      )
      .subscribe(
        () => this.toLogin(),
        (resp: HttpErrorResponse) => this.#formErrorsService.setFormErrors(resp, this.form),
      );
  }

  private createForm() {
    this.form = this.#fb.group({
      newPassword: [ '', [ Validators.required, BoxValidators.passwordComplexity ] ],
      confirmPassword: [ '', [ Validators.required ] ]
    }, { validators: [ BoxValidators.matchPassword('newPassword', 'confirmPassword') ]});
  }
}
