import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { AppConfig, BOX_APP_CONFIG } from '@bc-libs/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bcc-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: [ './otp-input.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpInputComponent implements OnDestroy {
  @Input() color: ThemePalette = 'accent';
  @Output() inputChanged = new EventEmitter<string>();
  @Output() filled = new EventEmitter<string>();
  @ViewChildren('input') inputElements: QueryList<ElementRef<HTMLInputElement>>;
  form: FormGroup;
  focusedIndex: number;
  prefixArray: string[];
  backgroundClass: string;

  @Input() set length(val: number) {
    this._length = val;

    this.setInputsControl();
  }

  get length(): number {
    return this._length;
  }

  private _length = 6;

  @Input() set disabled(val: boolean) {
    this._disabled = val;
    this._disabled ? this.inputs.disable({ emitEvent: false }) : this.inputs.enable({ emitEvent: false });
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input() set prefix(v: string) {
    this._prefix = v;
    this.prefixArray = this._prefix.split('');
  }

  get prefix(): string {
    return this._prefix;
  }

  private _disabled: boolean;
  private _prefix: string;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    @Optional() @Inject(BOX_APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.createForm();

    const { paletteClasses } = this.appConfig;

    if (paletteClasses) {
      this.backgroundClass = paletteClasses[ this.color ]?.background;
    }
  }

  get lengthArray(): string[] {
    return new Array(this.length).fill('');
  }

  get inputs(): FormArray {
    return this.form.get('inputs') as FormArray;
  }

  get value(): string {
    return this.inputs.value.join('');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  focus(index: number): void {
    const input = this.inputElements.get(index);

    if (!input) {
      return;
    }

    input.nativeElement.focus();
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();

    const data = event.clipboardData.getData('text');
    const value = this.lengthArray.map((v, i) => data.charAt(i));

    this.inputs.setValue(value);
  }

  onBeforeInput(event: InputEvent, index: number): void {
    const control = this.inputs.at(index);

    control.setValue('');

    if (event.inputType === 'deleteContentBackward') {
      this.focus(index - 1);
      event.preventDefault();
    }
  }

  onInput(event: Event, index: number): void {
    const control = this.inputs.at(index);
    const data = (event as InputEvent).data;

    if (data) {
      control.setValue(data.slice(-1));
      this.focus(index + 1);
    }
  }

  private createForm(): void {
    this.form = this.fb.group({});

    this.setInputsControl();

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.inputChanged.emit(this.value);

        if (this.value.length >= this.length) {
          this.filled.emit(this.value);
        }
      });
  }

  private setInputsControl(): void {
    const controls = this.lengthArray.map(() => this.fb.control(''));

    this.form.setControl('inputs', this.fb.array(controls));
  }
}
